<template>
  <div class="pageContainer">
    <div class="h_top">
      <div class="ht_son">
        <div class="ht_l">
          <span class="ht_l_span">最新公告</span>
          <span><a style="
    text-decoration: underline;
" href="https://wj.qq.com/s2/10403195/98fb/" target="_blank">配置器使用问卷调查</a>！！！</span>
        </div>
        <div class="ht_r">
          <span>查看更多 ></span>
        </div>
      </div>
    </div>
    <div class="h_nav">
      <div class="h_nav_son">
        <el-cascader
                class="hns_check"
                v-model="values"
                ref='ownArea'
                :options="options"
                :show-all-levels="false"
                placeholder="查看全部商品"
                :props="{ expandTrigger: 'hover',checkStrictly: true }"
                clearable
                @change="handleChange" >

        </el-cascader>
        <div class="hns_right">
          <span>Q系机柜</span>
          <span>一体机</span>
          <span>微模块</span>
          <span>超融合</span>
          <span>储能</span>
          <span>服务器机柜</span>
        </div>
      </div>
    </div>
    <div class="h_main">
      <div class="hm_l">
        <div class="hml_top">解决方案</div>
        <div class="hml_bot">
          <!--<el-menu
                  class="el-menu-vertical-demo"
                  :default-openeds="openeds"
                  @open="handleOpen"
                  @close="handleClose">

            <el-submenu index="1">
              <span slot="title" >数据中心</span>
              <router-link :to="{path:'/moduleDetial'}">
              <el-menu-item index="1-1">
                冷热通道
              </el-menu-item>
              </router-link>
              <router-link :to="{path:'/moduleDetial',query: {type:1}}">
                <el-menu-item index="1-2">
                  模块化数据中心
                </el-menu-item>
              </router-link>
              <router-link :to="{path:'/moduleDetial'}">
                <el-menu-item index="1-3">
                  微型数据中心
                </el-menu-item>
              </router-link>
              <router-link :to="{path:'/moduleDetial'}">
                <el-menu-item index="1-4">
                  集装箱数据中心
                </el-menu-item>
              </router-link>
            </el-submenu>

            <el-submenu index="2">
              <span slot="title">超融合桌面</span>
              <router-link :to="{path:'/moduleDetial'}">
                <el-menu-item index="2-1">
                  普通云桌面
                </el-menu-item>
              </router-link>
              <router-link :to="{path:'/moduleDetial'}">
                <el-menu-item index="2-1">
                  GPU云桌面
                </el-menu-item>
              </router-link>
              <router-link :to="{path:'/moduleDetial'}">
                <el-menu-item index="2-1">
                  超融合
                </el-menu-item>
              </router-link>
            </el-submenu>
            <el-submenu index="3">
              <span slot="title">智慧储能</span>
              <router-link :to="{path:'/moduleDetial'}">
                <el-menu-item index="3-1">
                  储能集装箱
                </el-menu-item>
              </router-link>
              <router-link :to="{path:'/moduleDetial'}">
                <el-menu-item index="3-2">
                  一体化储能箱
                </el-menu-item>
              </router-link>
              <router-link :to="{path:'/moduleDetial'}">
                <el-menu-item index="3-3">
                  5G基站储能箱
                </el-menu-item>
              </router-link>
            </el-submenu>
          </el-menu>-->
          <el-menu
                  class="el-menu-vertical-demo"
                  :default-openeds="openeds"
                  @open="handleOpen"
                  @close="handleClose">

            <el-submenu index="1">
              <span slot="title" >数据中心</span>
              <router-link :to="{path:'/moduleDetialhotandcold',query: {type:1}}">
              <el-menu-item index="1-1">
                冷热通道
              </el-menu-item>
              </router-link>

              <router-link :to="{path:'/moduleDetial',query: {type:1}}">
                <el-menu-item index="1-2">
                  模块化数据中心
                </el-menu-item>
              </router-link>
              <router-link :to="{path:'/miniDate',query: {type:1}}">
                <el-menu-item index="1-3">
                  微型数据中心
                </el-menu-item>
              </router-link>
                <!-- <el-menu-item index="1-3">
                  微型数据中心
                </el-menu-item> -->
                <el-menu-item index="1-4">
                  集装箱数据中心
                </el-menu-item>
            </el-submenu>

            <!-- <el-submenu index="2">
              <span slot="title">超融合桌面</span>

                <el-menu-item index="2-1">
                  普通云桌面
                </el-menu-item>

                <el-menu-item index="2-1">
                  GPU云桌面
                </el-menu-item>

                <el-menu-item index="2-1">
                  超融合
                </el-menu-item>
            </el-submenu>
            <el-submenu index="3">
              <span slot="title">智慧储能</span>

                <el-menu-item index="3-1">
                  储能集装箱
                </el-menu-item>

                <el-menu-item index="3-2">
                  一体化储能柜
                </el-menu-item>

                <el-menu-item index="3-3">
                  5G基站储能柜
                </el-menu-item>
            </el-submenu> -->
          </el-menu>
        </div>
      </div>
      <div class="hm_r">
        <el-carousel :interval="5000" arrow="always">
          <el-carousel-item  key="1">
            <router-link :to="{path:'/moduleDetial'}">
            <img src="../image/1.png">
            </router-link>
          </el-carousel-item>
          <el-carousel-item  key="2">
            <router-link :to="{path:'/moduleDetial'}">
            <img src="../image/2.png"></router-link>
          </el-carousel-item>
          <el-carousel-item  key="3">
            <router-link :to="{path:'/moduleDetial'}">
            <img src="../image/3.png"></router-link>
          </el-carousel-item>
          <el-carousel-item  key="4">
            <router-link :to="{path:'/moduleDetial'}">
            <img src="../image/4.png"></router-link>
          </el-carousel-item>
        </el-carousel>

        <div class="hm_r_bot" v-loading="listLoad">
          <div v-if="proList.length==0" style="text-align: center; margin-top: 50px;">暂无数据</div>

          <div @click="goDetial(item.cid)" class="hm_r_bot_list" v-for="item in proList">

            <img :src="item.imagePath">
            <span>¥{{item.priceMin}}-{{item.priceMax}}</span>
            <p>{{item.name}}</p>

          </div>
         <!-- <router-link :to="{path:'/productDetial',query: {id:item.params.CID}}"> </router-link>-->
          <!--<div class="hm_r_bot_list">
            <img src="../image/text.png">
            <span>￥ 4950.00</span>
            <p>Q5系列服务器机柜/ 数据中心机柜</p>
          </div>
          <div class="hm_r_bot_list">
            <img src="../image/text.png">
            <span>￥ 4950.00</span>
            <p>Q5系列服务器机柜/ 数据中心机柜</p>
          </div>
          <div class="hm_r_bot_list">
            <img src="../image/text.png">
            <span>￥ 4950.00</span>
            <p>Q5系列服务器机柜/ 数据中心机柜</p>
          </div>
          <div class="hm_r_bot_list">
            <img src="../image/text.png">
            <span>￥ 4950.00</span>
            <p>Q5系列服务器机柜/ 数据中心机柜</p>
          </div>-->
        </div>

      </div>
    </div>
  </div>

</template>

<script>
  import {
    mainList,
    productMenuList,
  } from "@/Api/finance/home";
  import { encrypt,decrypt } from "@/Util/auth";
  import $ from 'jquery'

export default {
  name: "home",

  data() {
    return {
      openeds:['1','2','3'],
      values: [],
      options: [],
      proList: [],
      listLoad: false,
    }
  },
  watch:{
    '$store.state.searchName': function () {
      //你需要执行的代码
      this.getMain2(this.$store.state.searchName)
    }

  },
  mounted() {
    this.getMainList()
    this.getMain([0])
  },
  methods: {
    goDetial(cid){
      this.$router.push({name:'productDetial',query: {id:cid}})
    },
    getMainList(){
      mainList().then(res => {
        let dd= JSON.parse(decrypt(res))
        console.log(dd)
        if (dd.code == 200) {

          this.options=dd.data
        }
        else {
          alert(dd.msg);
        }

      })

    },
    handleClick(val){


    },
    handleChange(value) {

      if(value.length==0){
        this.getMain([0])
      }else {
        this.getMain(value)
        this.$refs.ownArea.dropDownVisible = false;
      }

    },
    getMain(ida){

      this.listLoad=true
      let ids=ida[ida.length-1]
      let obj = {
        cid: ids,
      };
      let ss= encrypt(JSON.stringify(obj))
      productMenuList(ss).then(res => {
        let dd= JSON.parse(decrypt(res))

        if (dd.code == 200) {
          this.listLoad=false
          this.proList=dd.data

        }else  if (dd.code == 204) {
          this.listLoad=false
          this.proList=[]

        }else {
          this.listLoad=false
          alert(dd.msg);
        }

      })
    },
    getMain2(name){
      this.values=[]
      this.listLoad=true
      let obj = {
        cid: 0,
        name:name
      };
      let ss= encrypt(JSON.stringify(obj))
      productMenuList(ss).then(res => {
        let dd= JSON.parse(decrypt(res))

        if (dd.code == 200) {
          this.listLoad=false
          this.proList=dd.data

        }else  if (dd.code == 204) {
          this.listLoad=false
          this.proList=[]

        }else {
          this.listLoad=false
          alert(dd.msg);
        }

      })
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  }
};
</script>
<style lang="less">
  .el-cascader-panel{
  .el-radio{
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
    top: 10px;
    right: -10px;
  }
  .el-radio__input{
    visibility: hidden;
  }
  .el-cascader-node__postfix{
    top: 10px;
  }
  }
</style>
<style lang="less" scoped>


.h_top{

  width: 100%;
  background: #EEEEEE;
  height: 42px;
  border: 1px solid #E5E5E5;
  .ht_son{
    width: 1200px;
    margin: 0px auto;
    .ht_l{
      float: left;
      .ht_l_span{
        font-size: 18px;
        line-height: 42px;
        color: #060001;
        font-weight: 600;
        margin-right: 40px;
        background: url("../image/tongzhi.png") -1px 7px   no-repeat;
        padding-left: 30px;
        width: 104px;
        float: left;
      }
      span{
        font-size: 16px;
        color: #333333;line-height: 42px;
        width: 930px;
        overflow: hidden; /*溢出隐藏*/
        text-overflow: ellipsis; /*以省略号...显示*/
        white-space: nowrap; /*强制不换行*/
        display: block;
      }
    }
    .ht_r{
      float: right;
      width: 88px;
      height: 25px;
      border: 1px solid #333333;
      font-size: 14px;
      text-align: center;
      line-height: 25px;
      cursor: pointer;
      margin-top: 8px;
    }
  }
}
.h_nav{
    width: 100%;
    height: 62px;
  background: white;
    border-bottom: 3px solid #0264B7;
    .h_nav_son{
      width: 1200px;
      margin: 0px auto;
      position: relative;
      /deep/.hns_check{
        margin-top: 8px;
        float: left;
        input{
          width: 173px;
          height: 50px;
          background: #0264B7;
          color: white;
          font-size: 16px;
          font-weight: bold;
        }
        input::-webkit-input-placeholder {
                          /* placeholder颜色  */
                         color: white;
                          /* placeholder字体大小  */
                         font-size: 16px;
                           /* placeholder位置  */
                         text-align: center;
        }
        .el-input__inner{
          border-radius: 0px;
          border: 0px;
          text-align: center;
        }
        .el-input__suffix{
          color: white;
        }
      }
      .hns_right{
        width: 900px;
        float: right;
        span{
          display: block;font-size: 16px;
          font-weight: bold;
          color: #333333;
          width: 150px;
          float: left;
          line-height: 62px;
          cursor: pointer;
          transition: all  .25s  ease-in;
          -moz-transition: all  .25s  ease-in;
          -webkit-transition: all  .25s  ease-in;
        }
        span:hover{
          transform:translateY(-4px);
        }
      }
    }
  }
  .h_main{
    width: 1200px;
    margin: 0px auto;
    /deep/.hm_l{
      width: 220px;
      float: left; margin-top: 25px;
      .hml_top{
        font-weight: bold;
        color: #FFFFFF;
        font-size: 18px;
        height: 42px;
        background: #2C2D2D;
        line-height: 42px;
        text-align: center;
      }
      .el-submenu__title{
        font-size: 18px;
        font-weight: bold;
      }
      .el-menu-item{
        font-size: 16px;
      }
    }
    .hm_r{
      width: 900px;
      float: right;
      margin-top: 25px;
      .hm_r_bot{
        margin-top: 30px;
        min-height: 400px;
        margin-bottom: 20px;
        .hm_r_bot_list:hover{
          box-shadow: 0px 0px 47px 4px rgba(0, 0, 0, 0.15);
          transform:translateY(-8px);
        }
        .hm_r_bot_list{
          transition: all  .25s  ease-in;
          -moz-transition: all  .25s  ease-in;
          -webkit-transition: all  .25s  ease-in;
          background: white;
          cursor: pointer;
          margin-right: 25px;
          margin-bottom: 10px;
          float: left;
          width: 200px;
          height: 228px;
          img{
            width: 200px;
            height: 134px;
          }
          span{
            color: #E93B42;
            font-size: 16px;
            display: block;
            width: 154px;
            text-align: left;
            margin: 0px auto;margin-top: 10px;
          }
          p{
            font-weight: bold;margin: 0px auto;
            color: #333333;font-size: 14px;
            display: block;
            width: 154px;
            text-align: left;
            margin-top: 10px;
            line-height: 20px;
          }
        }
      }

      .el-carousel__item h3 {
        color: #475669;
        font-size: 18px;
        opacity: 0.75;
        line-height: 300px;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: #d3dce6;
      }
    }
  }
</style>
